export default {
  components: {},
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    loadSettings(setting_arr, cb) {
      this.$ajax.post('/settings/get', { arr: setting_arr }, (err, result) => {
        cb(err, result);
      });
    },
  },
  mounted() {

  },
  beforeMount() {
  },
  created() {
  },
  beforeCreate() {
  },
};
