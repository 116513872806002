<template>
  <div class="modal fade " ref="downloadLogModal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">ファイルのダウンロード時間</h5>

          <button type="button" @click="onClose('no')" class="btn-close" aria-label="Close"></button>

        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" style="">
                <table v-if="contentData != null"  class="table table-striped table-hover table-lg">
                  <thead>
                    <tr v-if="contentData.length > 0">
                      <th style="">#</th>
                      <th style="">時間</th>
                      <th style="text-align: start;">IP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in contentData" :key="index">
                      <td>{{ item.seq_id }}</td>
                      <td>{{ item.download_datetime }}</td>
                      <td style="text-align: start;">{{ item.ip }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" :class="['btn', ok_class]" @click="onClose('yes')">{{ ok_text }}</button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';

export default {
  name: 'download-log-modal',
  data() {
    return {
      modalElem: null,
      content_data: null,
      ok_text: '閉じる',
      ok_class: 'btn-primary',
      on_confirm: () => { },
    };
  },
  mixins: [],
  components: {},
  computed: {
    contentData: {
      get() {
        return this.content_data;
      },
      set(v) {
        this.content_data = v;
      },
    },
  },
  methods: {
    onClose(answer) {
      this.modalElem.hide();
      this.on_confirm(answer);
    },
    showModal(data) {
      this.loadLogs(data);
      this.modalElem.show();
    },
    loadLogs(data) {
      console.log('TEST DEBUG 231201 (60 at download-log-modal.vue)[01:02]: ', { data });
      const api_url = `/attach/load_logs/${data.attach_id}/${data.file_id}`;
      this.$ajax.post(api_url, data, (err, result) => {
        console.log('TEST DEBUG 231201 (62 at download-log-modal.vue)[00:57]: ', { err, result });
        if (err) {
          this.$notify({
            type: 'error',
            title: '予期しないエラー',
            text: err,
          });
        }
        let total = result.length;
        result.forEach((item, _index) => {
          total -= 1;
          item.seq_id = total;
        });
        this.contentData = result;
      });
    },
  },
  beforeCreate() { },
  created() { },
  beforeMount() { },
  mounted() {
    this.modalElem = new Modal(this.$refs.downloadLogModal);
  },
  beforeUpdate() { },
  updated() { },
  beforeUnmount() { },
  unmounted() { },
  errorCaptured() { },
  renderTracked() { },
  renderTriggered() { },
  activated() { },
  deactivated() { },
};
</script>
<style scoped>
.btn-primary {
    background-color: #6b71f2; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}
.modal-body {
  max-height: calc(100vh - 210px); /* Adjust 210px based on your header and footer height */
  overflow-y: auto;
}
</style>
