<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div :style="(center)?'display: grid; place-items: center;':''">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SenderContainer',
  props: {
    msg: String,
    center: Boolean,
  },
};
</script>

<style scoped>
.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 90%;
    width: 90%;
}
</style>
