export default {
  inject: ['globalState'],
  components: {},
  data() {
    return {
      setting_dictionary: 0,
      flag_debug: false,
    };
  },
  computed: {
  },
  methods: {
    dictIsLoaded() {
      return this.globalState.dictionary.dictionary_loaded || false;
    },
    onIsLoaded(cb) {
      if (this.globalState.dictionary.dictionary_loaded === false) {
        setTimeout(() => {
          this.onIsLoaded(cb);
        }, 300);
      } else {
        return true;
      }
    },
    reloadDictionary(cb = () => {}) {
      console.log('TEST DEBUG 240112 (16 at dictionary.js)[11:55] WILL LOAD: ', { });
      this.$ajax.post('/dictionary/load?from=sender_mixin', {}, (err, result) => {
        console.log('TEST DEBUG 240112 (18 at dictionary.js)[11:56]: LOADED', { err, result });
        if (result) {
          Object.keys(result).forEach((key) => {
            this.globalState.dictionary[key] = result[key];
          });
          this.globalState.dictionary.dictionary_loaded = true;
          console.log('TEST DEBUG 240112 (19 at dictionary.js)[11:51]: SET', { dict: this.globalState.dictionary });
        }
        cb(err, result);
      });
    },
    _d(_id, content, comment = '', meta = false) {
      let id = _id;
      if (this.dict_prefix) {
        id = `${this.dict_prefix}_${id}`;
      }
      return this.dic(id, content, comment, meta);
    },
    _gd(id, content, comment = '', meta = false) {
      return this.dic(id, content, comment, meta);
    },
    returnContent(content, id, meta = false) {
      const key_prefix = '{{';
      const key_suffix = '}}';
      let ret_content = content;
      if (this.flag_debug) {
        return `(${id})${content}`;
      }
      if (meta) {
        Object.keys(meta).forEach((key) => {
          ret_content = ret_content.replace(`${key_prefix}${key}${key_suffix}`, meta[key]);
        });
      }

      return ret_content;
    },
    dic(id, content, comment = '', meta = false) {
      if (id === '') {
        alert(`Empty dictionary id:${content}`);
      }
      if (this.globalState.dictionary[id]) {
        // console.log('TEST DEBUG 240112 (37 at dictionary.js)[13:02]: USE', { id , return :this.globalState.dictionary[id]});
        return this.returnContent(this.globalState.dictionary[id].replace(/\n/g, '<br>'), id, meta);
      }
      if (this.setting_dictionary > 10) {
        console.log('TEST DEBUG 240112 (41 at dictionary.js)[13:02]: ', { id, setting_dictionary: this.setting_dictionary });
        return this.returnContent(content, id, meta);
      }
      console.log('TEST DEBUG 240112 (39 at dictionary.js)[12:36]: ', { setting_dictionary: this.setting_dictionary });
      if (this.dictIsLoaded() === true) {
        this.setting_dictionary += 1;
        let updatedComment = comment;
        if (comment.indexOf('sender') === -1) {
          updatedComment += ' at sender';
        }
        const obj = { id, content, comment: updatedComment };
        if (meta && Object.keys(meta).length > 0) {
          obj.meta_keys = Object.keys(meta).join(',');
        }
        this.$ajax.post('/dictionary/set', obj, (_err, _result) => {
          this.setting_dictionary -= 1;
          if (this.setting_dictionary === 0) {
            this.reloadDictionary();
          }
          console.log('Updated dictionary', { id, content, comment: updatedComment });
        });
      }
      console.log('TEST DEBUG 240112 (53 at dictionary.js)[13:03]: ', { content });
      this.globalState.dictionary[id] = content;
      return this.returnContent(content, id, meta);
    },
  },
  mounted() {

  },
  beforeMount() {
    this.flag_debug = localStorage.getItem('dictionary_flag_debug') === 'true';
  },
  created() {
    console.log('TEST DEBUG 240112 (45 at dictionary.js)[12:09]: ', { dic: this.globalState.dictionary });
    if (Object.keys(this.globalState.dictionary).length < 2) {
      this.reloadDictionary();
    }
  },
  beforeCreate() {

  },

};
