const { TYPES } = require('./constants'); // SELECT_FIELD_MODE
//

const arr = [
  { id: 'id', label: 'Unique Key', type: TYPES.SYSTEM },

  {
    id: 'alias_name',
    label: 'アタッチ名123',
    type: TYPES.INPUT,
  },
  {
    id: 'create_time',
    label: '作成日',
    type: TYPES.DATE_PICKER,
    tab_size: 80,
    sort: 'desc',
  },
  {
    id: 'attach_expire',
    label: '有効期限',
    type: TYPES.DATE_PICKER,
    show_time: true,
    format: 'YYYY/MM/DD HH:mm',
    tab_size: 100,
  },
  {
    id: 'deleted_time',
    label: '削除された',
    type: TYPES.DATE_PICKER,
    show_time: true,
    format: 'YYYY/MM/DD HH:mm',
    tab_size: 100,
  },
  {
    id: 'attach_count',
    label: 'ファイルの数',
    type: TYPES.INPUT,
    tab_size: 60,
    right_aligned: true,
  },
  {
    id: 'download_qty',
    label: 'ダウンロード数',
    type: TYPES.INPUT,
    tab_size: 60,
    right_aligned: true,
  },
  {
    id: 'attach_status',
    label: 'attach status',
    type: TYPES.INPUT,
    tab_size: 60,
  },
  {
    id: 'link_mode',
    label: 'リンク方向',
    type: TYPES.INPUT,
    tab_size: 50,
  },
];
const ret = {};
let s = 1;
arr.forEach((e) => {
  /* eslint-disable no-alert, no-console */
  const ex_pattern = '^[a-z][a-z0-9_]+$';
  if (!RegExp(ex_pattern).test(e.id)) { alert(`Invalid field ${e.id}`); }
  e.tab_label = e.tab_label || e.label;
  e.seq_id = s;
  s += 1;
  ret[e.id] = e;
  /* eslint-enable no-alert, no-console */
});
ret.array = arr;
module.exports.fields = ret;
module.exports.filed_types = TYPES;
