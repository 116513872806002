<template>
  <MainLayout menu_id="settings">
    <SenderContainer>
      <ul class="nav nav-tabs" style="margin-top:0px;margin-bottom:0px;" role="tablist">
        <li class="nav-item" role="presentation">
          <a :class="(pageMode === 'my-data')? 'nav-link active':'nav-link inactive_tab'"   href="/settings/my-data"
          v-html="_d('settings_tab_title_my_settings', '私の設定', 'Settings tab my settings title.')"
          data-cy="my-data-link"
          ></a>
        </li>
        <li class="nav-item" role="presentation" v-if="showAdmin">
          <a :class="(pageMode === 'user')? 'nav-link active':'nav-link inactive_tab'"  href="/settings/user"
          v-html="_d('settings_tab_title_user', 'ユーザー', 'Settings tab Users title.')"
          data-cy="user-link"
          ></a>
        </li>
        <li class="nav-item" role="presentation" v-if="showAdmin">
          <a :class="(pageMode === 'log')? 'nav-link active':'nav-link inactive_tab'" href="/settings/log"
          v-html="_d('settings_tab_title_log', '管理者ログ', 'Settings tab Log title.')"
          data-cy="log-link"
          ></a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div v-if="pageMode === 'my-data'"
          style="margin-top:20px;"
          class="tab-pane fade show active"
          id="my-data"
          role="tabpanel"
          aria-labelledby="pills-my-data-tab">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row" style="width:200px;"
                  v-html="_d('settings_user_tab_table_user_name', 'User name', 'Settings / User / Table / User name .')"
                  ></th>
                <td colspan="2">{{loaded_data.user_name}}</td>
              </tr>
              <tr>
                <th scope="row"
                v-html="_d('settings_user_tab_table_display_name', 'Display name', 'Settings / User / Table / Display name .')"
                ></th>
                <td data-cy="display-name-info">{{loaded_data.display_name}}</td>
              </tr>
              <tr>
                <th scope="row"
                  v-html="_d('settings_user_tab_table_email', 'Email', 'Settings / User / Table / Email .')"
                  ></th>
                <td data-cy="email-info">{{loaded_data.email}}</td>
              </tr>
              <tr>
                <th scope="row"
                  v-html="_d('settings_user_tab_table_email_notification', '電子メールによる通知', 'Settings / User / Table / Email notification .')"
                  ></th>
                <td data-cy="email-info">{{(loaded_data.notification_enabled === 1?logUserNotificationEnabled:logUserNotificationDisabled)}}</td>
              </tr>
            </tbody>
          </table>

          <button class="custom_user_buttons btn btn-primary" style="margin-right: 10px;;" @click.prevent="onChangePassword"
          v-html="_d('settings_my_settings_btn_change_password', 'Change password', 'Settings / User / Button / Change password .')"
          data-cy="change-password-button"
          ></button>
          <button class="custom_user_buttons btn btn-primary" style="margin-right: 10px;;" @click.prevent="onChangeEmail"
          v-html="_d('settings_my_settings_btn_change_email', 'Change email', 'Settings / User / Button / Change email.')"
          data-cy="change-email-button"
          ></button>
          <button class="custom_user_buttons btn btn-primary" style="margin-right: 10px;;" @click.prevent="onChangeName"
          v-html="_d('settings_my_settings_btn_change_display_name', 'Change display name', 'Settings / User / Button / Change display name.')"
          data-cy="change-display-name-button"
          ></button>
        </div>
        <div  v-if="showAdmin && pageMode === 'user'"
          class="tab-pane fade show active"
          id="admin-data"
          role="tabpanel"
          aria-labelledby="pills-admin-data-tab">
            <AgTable ref="userTableRef" :useAddNew="true" :useSearch="true" :allFields="userFieldList"
              :tabFields="userTabFieldList" apiName="user" :apiRequestOptions="userListGetApiRequestOptions()"
              @onRowClicked="onUserRowClicked" @onAddNew="onNewUser"></AgTable>
        </div>
        <div  v-if="showAdmin && pageMode === 'log'"
          class="tab-pane fade show active"
          id="log"
          role="tabpanel"
          aria-labelledby="pills-log-tab">
              <AgTable ref="logTableRef" :useAddNew="false" :useSearch="true" :allFields="logFieldList"
              :tabFields="logTabFieldList" apiName="log" :apiRequestOptions="logListGetApiRequestOptions()"
              :apiForceSortOptions='{ id: "log_datetime", direction: "desc" }'
              @onRowClicked="onLogRowClicked"></AgTable>
        </div>
      </div>
    </SenderContainer>
  </MainLayout>
  <UserFormModal ref="formModalRef"></UserFormModal>
  <ConfirmationModal ref="confirmationModalRef" ></ConfirmationModal>
</template>
<script>
import UserFormModal from '@/components/modals/generic-form-modal.vue';
import ConfirmationModal from '@/components/modals/confirmation-modal.vue';
import SenderContainer from '@/components/sender-container.vue';
import MainLayout from '@/layouts/mainLayout.vue';
import Dictionary from '@/mixins/dictionary';
import AgTable from '@/components/ag-table.vue';
import _ from 'lodash';
import constants from '../../../api/rules/constants';
import { fields as log_fields } from '../../../api/rules/fields_log';
import { fields as user_fields } from '../../../api/rules/fields_user';

export default {
  data() {
    return {
      dict_prefix: 'settings',
      show_admin: false,
      loaded_data: {},

    };
  },
  routes: [{
    // path: '/menu', name: 'Menu', component: this, meta: { requiresAuth: true },
    path: '/settings/:mode', name: 'Settings', component: this, meta: { requiresAuth: true },
  }],
  mixins: [Dictionary],
  computed: {
    pageMode() {
      return this.$route.params.mode;
    },
    showAdmin: {
      get() {
        return this.show_admin;
      },
      set(v) {
        this.show_admin = v;
      },
    },
    userFieldList() {
      return user_fields;
    },
    logFieldList() {
      return log_fields;
    },
    logUserNotificationEnabled() {
      return this._d('log_user_notification_enabled', '有効', 'At the log page, a word to describe that an user notification is enabled.');
    },
    logUserNotificationDisabled() {
      return this._d('log_user_notification_disabled', '無効', 'At the log page, a word to describe that an user notification is disabled.');
    },
    logTabFieldList() {
      const list = [
        _.extend(log_fields.display_name, {
          tab_size: 100,
          forceCellRenderer: (params) => params.value || params?.data?.log_data?.changer_display_name || '',
        }),
        _.extend(log_fields.alias_name, {
          tab_size: 150,
          forceCellRenderer: (params) => params.value || params?.data?.log_data?.alias_name || '',
        }),
        _.extend(log_fields.log_datetime, { tab_size: 130 }),
        // _.extend(log_fields.log_type, {tab_size:50}),
        _.extend(log_fields.log_data, {
          tab_size: 550,
          forceCellRenderer: (params) => {
            const active = this._d('log_user_status_changed_status_active', 'Active', 'At the log page, a word to describe that an user status as Active.');
            const inactive = this._d('log_user_status_changed_status_inactive', 'Inactive', 'At the log page, a word to describe that an user status as Inactive. ');

            const log_user_notification_enabled = this.logUserNotificationEnabled;
            const log_user_notification_disabled = this.logUserNotificationDisabled;

            const ip = params?.data?.ip || '';
            let display_name = params?.data?.display_name || '';
            let {
              user_name, file_id, file_name, file_size, file_size_kb, user_id,
              file_size_mb, old_len, new_len, email, changer,
              user_level, id, user_status, new_display_name, old_display_name, new_email,
              old_email, new_status, old_status, new_access_level, old_access_level,
              alias_name, attach_status, attach_expire, mfa_address_list, notification_enabled,
              new_notification_enabled, old_notification_enabled,
            } = '';
            if (params?.data?.log_type) {
              switch (params.data.log_type) {
                case constants.LOG_TYPE.LOGIN:
                  user_name = params?.data?.log_data?.user_name || '';
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_login_message', 'Login', 'At the log page, a message to describe the login event.', { ip, display_name, user_name });
                case constants.LOG_TYPE.LOGOUT:
                  user_name = params?.data?.log_data?.user_name || '';
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_logout_message', 'Logout', 'At the log page, a message to describe the logout event.', { ip, display_name, user_name });
                case constants.LOG_TYPE.CHANGE_OWN_PASSWORD:
                  old_len = params?.data?.log_data?.old_len || '';
                  new_len = params?.data?.log_data?.new_len || '';
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_change_own_password_message', 'User changed own password.', 'At the log page, a message to describe the User change password event.', { ip, display_name, old_len, new_len });
                case constants.LOG_TYPE.CHANGE_OWN_DATA_EMAIL:
                  // FIXME: CHange it to solve the old data and the new data.

                  email = params?.data?.log_data?.email || '';
                  notification_enabled = parseInt(params?.data?.log_data?.notification_enabled || 0) === 1 ? log_user_notification_enabled : log_user_notification_disabled;
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_change_own_email_message', 'User changed own email to {{email}} with notification {{notification_enabled}}.', 'At the log page, a message to describe the User change email event.', { ip, display_name, email, notification_enabled });
                case constants.LOG_TYPE.CHANGE_OWN_DATA_NAME:
                  // FIXME: CHange it to solve the old data and the new data.
                  display_name = params?.data?.log_data?.display_name || '';
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_change_own_display_name_message', 'User changed own name to {{display_name}}.', 'At the log page, a message to describe the User change display_name event.', { ip, display_name });
                case constants.LOG_TYPE.CREATE_USER:
                  changer = params?.data?.display_name || '';
                  user_name = params?.data?.log_data?.user_name || '';
                  display_name = params?.data?.log_data?.display_name || '';
                  email = params?.data?.log_data?.email || '';
                  notification_enabled = parseInt(params?.data?.log_data?.notification_enabled || 0) === 1 ? log_user_notification_enabled : log_user_notification_disabled;
                  user_level = params?.data?.log_data?.user_level || '';
                  id = params?.data?.log_data?.id || '';
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_created_user', 'The user {{user_name}} was created.', 'At the log page, a message to describe that an user was created.', { ip, display_name, changer, user_name, email, user_level, id, notification_enabled });
                case constants.LOG_TYPE.DELETE_OTHER_USER:
                  changer = params?.data?.display_name || '';
                  user_name = params?.data?.log_data?.user_name || '';
                  user_status = params?.data?.log_data?.user_status || '';
                  id = params?.data?.log_data?.id || '';
                  display_name = params?.data?.log_data?.display_name || '';
                  email = params?.data?.log_data?.email || '';
                  notification_enabled = parseInt(params?.data?.log_data?.notification_enabled || 0) === 1 ? log_user_notification_enabled : log_user_notification_disabled;

                  // user_level = this.processLevel(params?.data?.log_data?.user_level);
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_deleted_user', 'The user 「{{display_name}}」 was deleted.', 'At the log page, a message to describe that an user was created.', { ip, display_name, user_status, changer, user_name, id, email, notification_enabled });
                case constants.LOG_TYPE.CHANGE_OTHER_USER_PASSWORD:
                  changer = params?.data?.display_name || '';
                  user_id = params?.data?.log_data?.user_id || '';
                  new_len = params?.data?.log_data?.new_len || '';
                  user_name = params?.data?.log_data?.user_name || '';

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_user_password_changed', 'The user {{user_name}} password was changed.', 'At the log page, a message to describe that an user password was changed.', { ip, display_name, changer, user_id, new_len, user_name });
                case constants.LOG_TYPE.CHANGE_OTHER_USER_NAME:
                  changer = params?.data?.display_name || '';
                  user_id = params?.data?.log_data?.user_id || '';
                  new_display_name = params?.data?.log_data?.display_name || '';
                  old_display_name = params?.data?.log_data?.old_display_name || '';

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_user_display_name_changed', 'The user 「{{old_display_name}}」 display name was changed to 「{{new_display_name}}」.', 'At the log page, a message to describe that an user display name was changed.', { ip, display_name, changer, user_id, new_display_name, old_display_name });
                case constants.LOG_TYPE.CHANGE_OTHER_USER_EMAIL:
                  changer = params?.data?.display_name || '';
                  user_id = params?.data?.log_data?.user_id || '';
                  display_name = params?.data?.log_data?.display_name || '';
                  new_email = params?.data?.log_data?.email || '';
                  old_email = params?.data?.log_data?.old_email || '';
                  notification_enabled = parseInt(params?.data?.log_data?.notification_enabled || 0) === 1 ? log_user_notification_enabled : log_user_notification_disabled;

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_user_email_changed', 'The user 「{{display_name}}」 email was changed from 「{{old_email}}」 to 「{{new_email}}」.', 'At the log page, a message to describe that an user email was changed.', { ip, display_name, changer, user_id, new_email, old_email, notification_enabled });
                case constants.LOG_TYPE.CHANGE_OTHER_USER_STATUS:
                  changer = params?.data?.display_name || '';
                  user_id = params?.data?.log_data?.user_id || '';
                  display_name = params?.data?.log_data?.display_name || '';
                  new_status = parseInt(params?.data?.log_data?.user_status) === 1 ? active : inactive;
                  old_status = parseInt(params?.data?.log_data?.old_user_status) === 1 ? active : inactive;

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_user_status_changed', 'The user 「{{display_name}}」 status was changed from 「{{old_status}}」 to 「{{new_status}}」.', 'At the log page, a message to describe that an user status was changed.', { ip, display_name, changer, user_id, new_status, old_status });
                case constants.LOG_TYPE.CHANGE_OTHER_USER_EMAIL_NOTIFICATION:
                  user_id = params?.data?.log_data?.user_id || '';
                  display_name = params?.data?.log_data?.display_name || '';

                  new_notification_enabled = parseInt(params?.data?.log_data?.notification_enabled) === 1 ? log_user_notification_enabled : log_user_notification_disabled;
                  old_notification_enabled = parseInt(params?.data?.log_data?.old_notification_enabled) === 1 ? log_user_notification_enabled : log_user_notification_disabled;

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_user_notification_enabled_changed', 'The user 「{{display_name}}」 email notification was changed from 「{{old_notification_enabled}}」 to 「{{new_notification_enabled}}」.', 'At the log page, a message to describe that an user access level was changed.', { ip, user_id, display_name, new_notification_enabled, old_notification_enabled });

                case constants.LOG_TYPE.CHANGE_OTHER_USER_ACCESS_LEVEL:
                  user_id = params?.data?.log_data?.user_id || '';
                  display_name = params?.data?.log_data?.display_name || '';
                  new_access_level = this.processLevel(params?.data?.log_data?.user_level);
                  old_access_level = this.processLevel(params?.data?.log_data?.old_user_level);

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_user_access_level_changed', 'The user 「{{display_name}}」 access level was changed from 「{{old_access_level}}」 to 「{{new_access_level}}」.', 'At the log page, a message to describe that an user access level was changed.', { ip, user_id, display_name, new_access_level, old_access_level });
                case constants.LOG_TYPE.SAVE_URL:
                  alias_name = params?.data?.log_data?.alias_name || '';
                  attach_status = params?.data?.log_data?.attach_status || '';
                  attach_expire = params?.data?.log_data?.attach_expire || '';
                  mfa_address_list = params?.data?.log_data?.mfa_address_list || '';

                  if (params?.data?.log_data?.link_mode === 'send') {
                    // eslint-disable-next-line object-curly-newline
                    return this._d('log_send_user_created', 'An attach URL was created with name {{alias_name}}.', 'At the log page, a message to describe a send url was created.', { ip, display_name, alias_name, attach_status, attach_expire, mfa_address_list });
                  } if (params?.data?.log_data?.link_mode === 'receive') {
                    // eslint-disable-next-line object-curly-newline
                    return this._d('log_receive_user_created', 'An upload URL was created with name {{alias_name}}.', 'At the log page, a message to describe a receive url was created.', { ip, display_name, alias_name, attach_status, attach_expire, mfa_address_list });
                  }
                  return `SAVE_URL: ${params?.data?.log_data?.link_mode}`;
                case constants.LOG_TYPE.FILE_UPLOADED_TO_URL:
                  file_id = params?.data?.log_data?.file_id || '';
                  file_name = params?.data?.log_data?.file_name || '';
                  file_size = params?.data?.log_data?.file_size || '';
                  file_size_kb = parseInt(params?.data?.log_data?.file_size || 1) / 1024;
                  file_size_mb = parseInt(params?.data?.log_data?.file_size || 1) / 1024 / 1024;

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_file_uploaded_to_url', 'A file with name 「{{file_name}}」 and {{file_size}} bytes was uploaded.', 'At the log page, a message to describe a file was uploaded.', { ip, display_name, file_id, file_name, file_size, file_size_kb, file_size_mb });
                case constants.LOG_TYPE.FILE_DELETED_FROM_URL:
                  file_id = params?.data?.log_data?.file_id || '';
                  file_name = params?.data?.log_data?.file_name || '';
                  file_size = params?.data?.log_data?.file_size || '';
                  file_size_kb = parseInt(params?.data?.log_data?.file_size || 1) / 1024;
                  file_size_mb = parseInt(params?.data?.log_data?.file_size || 1) / 1024 / 1024;

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_file_deleted_from_url', 'A file with name 「{{file_name}}」 and {{file_size}} bytes was deleted.', 'At the log page, a message to describe a file was deleted.', { ip, display_name, file_id, file_name, file_size, file_size_kb, file_size_mb });
                case constants.LOG_TYPE.DOWNLOAD_URL:
                  file_id = params?.data?.log_data?.file_id || '';
                  file_name = params?.data?.log_data?.file_name || '';
                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_file_downloaded_by_sender', 'A file with name 「{{file_name}}」 was downloaded by the user 「{{display_name}}」.', 'At the log page, a message to describe a file was downloaded by the recipient.', { ip, display_name, file_id, file_name });
                case constants.LOG_TYPE.RECIPIENT_FILE_UPLOADED_TO_URL:
                  file_id = params?.data?.log_data?.file_id || '';
                  file_name = params?.data?.log_data?.file_name || '';
                  file_size = params?.data?.log_data?.file_size || '';
                  file_size_kb = parseInt(params?.data?.log_data?.file_size || 1) / 1024;
                  file_size_mb = parseInt(params?.data?.log_data?.file_size || 1) / 1024 / 1024;

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_file_uploaded_to_url_by_recipient', 'The recipient uploaded a file with name 「{{file_name}}」 and {{file_size}} bytes.', 'At the log page, a message to describe a file was uploaded by the recipient.', { ip, display_name, file_id, file_name, file_size, file_size_kb, file_size_mb });
                case constants.LOG_TYPE.RECIPIENT_DOWNLOAD_URL:
                  file_id = params?.data?.log_data?.file_id || '';
                  file_name = params?.data?.log_data?.file_name || '';

                  // eslint-disable-next-line object-curly-newline
                  return this._d('log_file_downloaded_by_recipient', 'A file with name 「{{file_name}}」 was downloaded by the recipient at IP {{ip}}.', 'At the log page, a message to describe a file was downloaded by the recipient.', { ip, display_name, file_id, file_name });
                default:
                  return `***${params?.data?.log_type || '-'}`; // JSON.stringify(params.value);
              }
            } else {
              return '-';
            }
          },
        }),
      ];
      return list;
    },
    userTabFieldList() {
      const list = [
        _.extend(user_fields.user_name, { tab_size: 250 }),
        _.extend(user_fields.display_name, { tab_size: 250 }),
        _.extend(user_fields.user_status, {
          tab_size: 250,
          forceCellRenderer: (params) => {
            const active = this._d('tab_user_status_active', 'Active', 'At the user page, a word to describe that an user status as Active. it is used at user_status');
            const inactive = this._d('tab_user_status_inactive', 'Inactive', 'At the user page, a word to describe that an user status as Inactive. it is used at user_status');
            return (parseInt(params.value) === 1) ? active : inactive;
          },
        }),
        _.extend(user_fields.user_level, {
          tab_size: 250,
          forceCellRenderer: (params) => {
            switch (params.value) {
              case 1:
                return this._d('user_details_level_user', 'User', 'At the settings user page, a word to describe that an user level as User. it is used at user_level');
              case 5:
                return this._d('user_details_level_account_manager', 'Account Manager', 'At the settings user page, a word to describe that an user level as Account Manager. it is used at user_level');
              case 9:
                return this._d('user_details_level_master_admin', 'Master Admin', 'At the settings user page, a word to describe that an user level as Master Admin. it is used at user_level');
              default:
                return this._d('user_details_level_unknown', 'Unknown', 'At the settings user page, a word to describe that an user level as Unknown. it is used at user_level');
            }
          },
        }),
      ];
      return list;
    },
  },

  components: {
    MainLayout, UserFormModal, AgTable, ConfirmationModal, SenderContainer,
  },
  methods: {
    processLevel(level) {
      switch (parseInt(level)) {
        case 1:
          return this._d('tab_user_level_user', 'User', 'At the settings user page, a word to describe that an user level as User. it is used at user_level');
        case 5:
          return this._d('tab_user_level_account_manager', 'Account Manager', 'At the settings user page, a word to describe that an user level as Account Manager. it is used at user_level');
        default:
          return this._d('tab_user_level_unknown', 'Unknown', 'At the settings user page, a word to describe that an user level as Unknown. it is used at user_level');
      }
    },
    userListGetApiRequestOptions() { return { sub_search: { tenant_id: this.$route.params.id } }; },
    logListGetApiRequestOptions() { return { sub_search: { tenant_id: this.$route.params.id } }; },
    onLogRowClicked(ev) {
      console.log('onLogRowClicked', ev.data);
      // TODO: Implement it.
      // let body = "Log type: " + ev.data.log_type + "<br><pre>" + JSON.stringify(ev.data, null,2) + "</pre>";
      // this.confirmation_modal_config = {
      //   title: ev.data.log_datetime,
      //   body: body,
      //   dialog_style:"max-width: 800px;",
      //   buttons: [
      //     {
      //       label: "Close",
      //       class: 'btn-secondary',
      //       action: () => {}
      //     }
      //   ]
      // };
      // this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
    },
    onUserRowClicked(ev) {
      let user_level = {
        id: 'user_level',
        label: this._d('select_user_level_label', 'Level', 'At the settings user page, a word to describe that an user level. it is used at user_level'),
        type: 'select',
        value: ev.data.user_level,
        select_options: [
          { value: '1', label: this._d('option_user_level_user', 'User', 'At the settings user page, a word to describe that an user level as User. it is used at user_level') },
          { value: '5', label: this._d('option_user_level_account_manager', 'Account Manager', 'At the settings user page, a word to describe that an user level as Account Manager. it is used at user_level') },
        ],
      };
      if (ev.data.user_level === 9) {
        user_level = {
          id: 'user_level',
          label: this._d('modal_edit_user_level_label', 'Level', 'At the settings user page, a word to describe that an user level. it is used at user_level'),
          type: 'label',
          value: this._d('modal_user_level_account_manager', 'Account Manager', 'At the settings user page, a word to describe that an user level as Account Manager. it is used at user_level'),
        };
      }
      const form_fields = [
        // { id: "user_password", label: " Password", type: "text", value: "" },
        {
          id: 'user_name',
          label: this._d('edit_user_name_label', 'User Name', 'At the user page, a word to describe that an user name. it is used at user_name'),
          type: 'label',
          value: ev.data.user_name,
        },
        {
          id: 'display_name',
          label: this._d('edit_display_name_label', 'Display Name', 'At the user page, a word to describe that an user display name. it is used at display_name'),
          type: 'text',
          value: ev.data.display_name,
        },
        {
          id: 'email',
          label: this._d('edit_email_label', 'E-mail', 'At the user page, a word to describe that an user email. it is used at email'),
          type: 'text',
          value: ev.data.email,
        },
        {
          id: 'notification_enabled',
          label: this._d('settings_user_tab_table_email_notification', '電子メールによる通知', 'Settings / User / Table / Email notification .'),
          type: 'select',
          value: ev.data.notification_enabled || '0',
          select_options: [
            {
              value: '1',
              label: this.logUserNotificationEnabled,
            },
            {
              value: '0',
              label: this.logUserNotificationDisabled,
            },
          ],
        },
        {
          id: 'user_status',
          label: this._d('edit_user_status_label', 'Status', 'At the user page, a word to describe that an user status. it is used at user_status'),
          type: 'select',
          value: ev.data.user_status,
          select_options: [
            {
              value: '1',
              label: this._d('edit_user_status_active', 'Active', 'At the user page, a word to describe that an user status as Active. it is used at user_status'),
            },
            {
              value: '0',
              label: this._d('edit_user_status_inactive', 'Inactive', 'At the user page, a word to describe that an user status as Inactive. it is used at user_status'),
            },
          ],
        },
        user_level,
      ];
      this.myGenericForm(form_fields, `/user/edit_user/${ev.data.id}`, 'Edit user', { show_change_password: true, ev, reload_table: true });
    },
    onUserRowClickedForPassword(ev) {
      const form_fields = [
        {
          id: 'user_name',
          label: this._d('edit_pass_user_name_label', 'User Name', 'At the user page, a word to describe that an user name. it is used at user_name'),
          type: 'label',
          value: ev.data.user_name,
        },
        {
          id: 'display_name',
          label: this._d('edit_pass_display_name_label', 'Display Name', 'At the user page, a word to describe that an user display name. it is used at display_name'),
          type: 'label',
          value: ev.data.display_name,
        },
        {
          id: 'user_password',
          label: this._d('edit_pass_user_new_password_label', 'New Password', 'At the user page, a word to describe that an user password. it is used at user_password'),
          type: 'text',
          value: '',
        }];
      this.myGenericForm(form_fields, `/user/edit_user_password/${ev.data.id}`, 'Edit user password', { ev });
    },
    onNewUser() {
      const form_fields = [
        {
          id: 'user_name',
          label: this._d('new_user_name_label', 'User Name', 'At the user page, a word to describe that an user name. it is used at user_name'),
          type: 'text',
          value: '',
        },
        {
          id: 'user_password',
          label: this._d('new_user_password_label', 'Password', 'At the user page, a word to describe that an user password. it is used at user_password'),
          type: 'text',
          value: '',
        },
        {
          id: 'display_name',
          label: this._d('new_display_name_label', 'Display Name', 'At the user page, a word to describe that an user display name. it is used at display_name'),
          type: 'text',
          value: '',
        },
        {
          id: 'email',
          label: 'E-mail',
          type: 'text',
          value: '',
        },
        {
          id: 'user_status',
          label: this._d('new_user_status_label', 'Status', 'At the user page, a word to describe that an user status. it is used at user_status'),
          type: 'select',
          value: '1',
          select_options: [
            {
              value: '1',
              label: this._d('new_user_status_active', 'Active', 'At the user page, a word to describe that an user status as Active. it is used at user_status'),
            },
            {
              value: '0',
              label: this._d('new_user_status_inactive', 'Inactive', 'At the user page, a word to describe that an user status as Inactive. it is used at user_status'),
            },
          ],
        },
        {
          id: 'user_level',
          label: this._d('modal_new_user_level_label', 'Level', 'At the settings user page, a word to describe that an user level. it is used at user_level'),
          type: 'select',
          value: '1',
          select_options: [
            {
              value: '1',
              label: this._d('modal_option_user_level_user', 'User', 'At the settings user page, a word to describe that an user level as User. it is used at user_level'),
            },
            { value: '5', label: this._d('modal_option_user_level_account_manager', 'Account Manager', 'At the settings user page, a word to describe that an user level as Account Manager. it is used at user_level') },
          ],
        },
      ];
      this.myGenericForm(form_fields, '/user/create_user', 'Add new user', { reload_table: true });
    },
    onDeleteUserConfirmationModal(ev) {
      const id = ev.data.id;
      const user_name = ev.data.user_name;
      this.confirmation_modal_config = {

        title: this._d('delete_user_confirmation_question_title', 'Are you sure you want to delete this user?', 'At the user page, a question to confirm if the user wants to delete the user. it is used at settings page', { user_name, id }),
        body: this._d('delete_user_confirmation_question_text', 'The user and all this attachments will be deleted. This action cannot be undone. <br><br>User name: {{user_name}}<br>ID: {{id}}', 'At the user page, a question to confirm if the user wants to delete the user. it is used at settings page', { user_name, id }),
        buttons: [
          {
            label: this._d('delete_user_confirmation_question_yes_btn', 'Yes, delete', 'At the user page, a word to confirm that an user delete.'),
            class: 'btn-danger',
            cy: 'delete-user-confirmation-yes-button',
            action: () => {
              this.onDeleteUserConfirmationModalPhase2(id, user_name);
            },
          },
          {
            label: this._d('delete_user_confirmation_question_cancel_btn', 'No, cancel', 'At the user page, a word to cancel that an user delete.'),
            class: 'btn-secondary',
            action: () => {
            },
          },
        ],
      };
      this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
    },
    onDeleteUserConfirmationModalPhase2(id, name) {
      // FIXME: NEed to translate it.
      const form_fields = [
        {
          id: 'id',
          label: this._d('user_id_label', 'User ID', 'At the settings page, at confirm to delete phase 2, the label for user id'),
          type: 'label',
          value: id,
        },
        {
          id: 'name',
          label: this._d('user_name_label', 'User Name', 'At the settings page, at confirm to delete phase 2, the label for user name'),
          type: 'label',
          value: name,
        },
        {
          id: 'confirm_user_name',
          label: this._d('user_confirm_user_name_label', 'Type the 「User name」 to confirm you want to delete', 'At the settings page, at confirm to delete phase 2, the label for confirm user name to be deleted'),
          type: 'text',
          value: '',
        },
      ];
      const buttons = [
        {
          label: this._d('delete_user_confirmation_delete_btn', 'Delete', 'At the settings page, at confirm to delete phase 2, the label for delete button'),
          class: 'btn-danger',
          cy: 'delete-user-confirmation-delete-button',
          action: (_data) => {
            this.$refs.formModalRef.showForm(false, this._d('processing_delete_msg', 'Processing delete...', 'At the settings page, a word to describe that an record is processing. '));
            const typed_user_name = form_fields[2].value;
            if (typed_user_name === name) {
              this.$ajax.post(`/user/delete_user/${id}`, {}, (err, _result) => {
                if (err) {
                  this.$notify({
                    type: 'error',
                    title: this._d('unexpected_error_message_notification_at?delete', '予期しないエラー', 'An error message to an unexpected error'),
                    text: err,
                  });
                  this.$refs.formModalRef.showForm(true, err || this._d('unexpected_error_message_msg_at_delete', '予期しないエラー', 'An error message to an unexpected error'));
                } else {
                  this.$notify({
                    type: 'success',
                    title: this._d('delete_success_message_title', 'Deleted', 'A message title to describe that an user data was deleted.'),
                    text: this._d('delete_success_message_text', 'Success', 'A message text to describe that an user data was deleted.'),
                  });
                  this.$refs.formModalRef.modalElem.hide();
                  this.$refs.userTableRef.reloadData();
                }
              });
            } else {
              this.$notify({
                type: 'error',
                title: this._d('delete_error_message_title', 'Error', 'A message title to describe that the operation to deleted the user failed because the imputed data don\'t match.'),
                text: this._d('delete_error_message_text_notification', 'The 「User name」 typed is not the same as the user name to be deleted', 'A message text to describe that the operation to deleted the user because the imputed data don\'t match.'),
              });
              this.$refs.formModalRef.showForm(true, this._d('delete_error_message_text', 'The 「User name」 typed is not the same as the user name to be deleted', 'A message text to describe that the operation to deleted the user because the imputed data don\'t match.'));
            }
          },
        },
        { label: 'Cancel', class: 'btn-secondary', action: () => { this.$refs.formModalRef.modalElem.hide(); } },

      ];

      this.$refs.formModalRef.showForm(true, '');
      this.$refs.formModalRef.showModal({
        title: this._d('delete_user_confirmation_phase2_title', 'Last chance to cancel', 'At the settings page, at confirm to delete phase 2, the title for last chance to cancel'),
        body: false,
        form_fields,
        buttons,
        dialog_style: 'max-width: 800px;',

      });
    },
    myGenericForm(form_fields, api_url, title, option = {}) {
      const buttons = [];
      if (option.show_change_password) {
        buttons.push({
          label: this._d('user_change_password_label', 'Change password', 'At the user page, a word to describe that an user change password. it is used at user_change_password'),
          class: 'btn-info',
          style: 'margin-right: auto;',
          cy: 'change-own-password-button',
          action: () => {
            this.$refs.formModalRef.modalElem.hide();
            setTimeout(() => {
              this.onUserRowClickedForPassword(option.ev);
            }, 500);
          },
        });
      }
      if (parseInt(option?.ev?.data?.user_status) === 0 && option.show_change_password === true) {
        buttons.push({
          label: this._d('delete_user_btn', 'Delete user', 'At the user page, a button to describe that an user delete. it is used at delete_user'),
          class: 'btn-danger',
          cy: 'delete-user-button',
          style: 'margin-right: auto;',
          action: () => {
            this.$refs.formModalRef.modalElem.hide();
            // Show a confirmation dialog
            this.onDeleteUserConfirmationModal(option.ev);
          },
        });
      }
      buttons.push({
        label: this._d('save_label', 'Save', 'At the user page, a word to describe that an user save. it is used at save'),
        class: 'btn-primary',
        cy: 'save-button',
        action: () => {
          buttons[0].disabled = true;
          buttons[1].disabled = true;
          this.$refs.formModalRef.showForm(
            false,
            this._d('processing_save_msg', 'Processing ...', 'At the settings page, a word to describe that an record is processing or saving.'),
          );
          const obj = { };
          form_fields.forEach((f) => {
            obj[f.id] = f.value;
          });
          if (option.validate_repeat_password) {
            if (obj.new_password !== obj.repeat_password) {
              const err_msg = this._d('password_repeat_not_same_msg', 'Password and Repeat Password are not the same', 'At the user page, a word to describe that an user password and repeat password are not the same. it is used at change password error message');
              this.$notify({
                type: 'error',
                title: this._d('unexpected_error_message_at_pass_validation', '予期しないエラー', 'An error message to an unexpected error'),
                text: err_msg,
              });
              buttons[1].disabled = false;
              buttons[0].disabled = false;
              this.$refs.formModalRef.showForm(true, `<span style='color:red'>${err_msg}</span>`);
              return;
            }
          }
          this.$ajax.post(api_url, obj, (err, result, error_data) => {
            buttons[1].disabled = false;
            buttons[0].disabled = false;
            if (err) {
              let err_msg = err;
              if (error_data.invalid_password) {
                err_msg = this._d('invalid_password_message', '無効なパスワード', 'An error message to an invalid password');
              }
              this.$notify({
                type: 'error',
                title: this._d('unexpected_error_message_title_at_save_api', '予期しないエラー', 'An error message to an unexpected error'),
                text: err_msg,
              });
              this.$refs.formModalRef.showForm(true, err_msg || this._d('unexpected_error_message_msg_at_save_api', '予期しないエラー', 'An error message to an unexpected error'));
            } else {
              this.$notify({
                type: 'success',
                title: this._d('saved_message', 'Saved', 'A message to describe that an user data was saved.'),
                text: this._d('success_message_at_save', 'Success', 'A message to describe that an user data was saved.'),
              });
              this.loadUserData(true);
              this.$refs.formModalRef.modalElem.hide();
              if (option.reload_table) {
                this.$refs.userTableRef.reloadData();
              }
            }
          });
        },
      });
      buttons.push({
        label: this._d('cancel_label', 'Cancel', 'At the user page, a word to describe that an user cancel. it is used at cancel'),
        class: 'btn-secondary',
        action: () => { this.$refs.formModalRef.modalElem.hide(); },
      });

      this.$refs.formModalRef.showForm(true, '');
      this.$refs.formModalRef.showModal({
        title,
        form_fields,
        buttons,
      });
    },

    onChangePassword() {
      const form_fields = [
        {
          id: 'old_password',
          label: this._d('old_password_label', 'Old Password', 'At the user page, a word to describe that an user old password. it is used at old_password'),
          type: 'password',
          value: '',
        },
        {
          id: 'new_password',
          label: this._d('new_password_label', 'New Password', 'At the user page, a word to describe that an user new password. it is used at new_password'),
          type: 'password',
          value: '',
        },
        {
          id: 'repeat_password',
          label: this._d('repeat_password_label', 'Repeat Password', 'At the user page, a word to describe that an user repeat password. it is used at repeat_password'),
          type: 'password',
          value: '',
        },
      ];
      this.myGenericForm(form_fields, '/user/change_own_password', this._d('title_change_password', 'パスワード変更', 'change password'), { validate_repeat_password: true });
    },
    onChangeEmail() {
      const form_fields = [
        {
          id: 'email',
          label: this._d('change_email_label', 'E-mail', 'At the user page, a word to describe that an user email. it is used at email'),
          type: 'text',
          value: this.loaded_data.email,
        },
        {
          id: 'notification_enabled',
          label: this._d('settings_user_tab_table_email_notification', '電子メールによる通知', 'Settings / User / Table / Email notification .'),
          type: 'select',
          value: this.loaded_data.notification_enabled || '0',
          select_options: [
            {
              value: '1',
              label: this.logUserNotificationEnabled,
            },
            {
              value: '0',
              label: this.logUserNotificationDisabled,
            },
          ],
        },
      ];
      this.myGenericForm(
        form_fields,
        '/user/change_own_data',
        this._d('change_email_label_form_title', 'Change Email', 'At the user page, a word to describe that an user change email. it is used at change_email'),
        {},
      );
    },
    onChangeName() {
      const form_fields = [
        {
          id: 'display_name',
          label: this._d('display_name_label', 'Display Name', 'At the user page, a word to describe that an user display name. it is used at display_name'),
          type: 'text',
          value: this.loaded_data.display_name,
        },
      ];
      this.myGenericForm(form_fields, '/user/change_own_data', this._d('title_change_name', 'ユーザ名変更', 'Change the display name'), {});
    },
    loadUserData(silent = false) {
      // load the user data
      this.$ajax.post('/user/get_own_data', {}, (err, result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_message', '予期しないエラー', 'An error message to an unexpected error'),
            text: err,
          });
        } else {
          this.loaded_data = result;
          if (silent === false) {
            this.$notify({
              type: 'success',
              title: this._d('success_message', 'Loaded', 'A message to describe that an user data was loaded.'),
              text: this._d('user_data_loaded_message', 'User data loaded', 'A message to describe that an user data was loaded.'),
            });
          }
        }
      });
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);

    const user_level = parseInt(localStorage.getItem('user_level') || 1);
    this.showAdmin = user_level === 5 || user_level === 9;
    this.loadUserData(true);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-large {
  margin: 10px 0px 10px 0px;;
  width:300px;
}
.btn-primary {
    background-color: #6b71f2; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}
.nav-link{
  color:#6268e0;
}
.inactive_tab {
  color: #6268e0;
}
@media (max-width: 550px) {
  .custom_user_buttons {
    margin-bottom: 20px;
    width: 100%
  }
}

</style>
