import { createRouter, createWebHistory } from 'vue-router';
import moment from 'moment';

const routes = [];
// Define here the name of each file that should be part to the router
[
  require('../views/login'),
  require('../views/logout'),
  require('../views/home'),
  // require('../views/new'),
  require('../views/history'),
  require('../views/attach'),
  require('../views/upload_link'),
  require('../views/settings'),
].forEach((file) => {
  console.log('TEST DEBUG 231015 (17 at index.js)[18:06]: ', { file });
  file.default.routes.forEach((r) => {
    console.log('TEST DEBUG 231015 (19 at index.js)[18:06]: ', { r });
    r.component = file.default;

    routes.push(r);
  });
});

// Here it configure the routes
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Here it authenticate the cookies, adust it based on the login rules
const checkCookies = () => {
  let found = 0;
  const s = document.cookie.split(';');
  s.forEach((c) => {
    const kv = c.trim().split('=');
    if (kv[0] === 'session_key' && kv[1].length > 70) {
      found += 1;
    }
    if (kv[0] === 'session_user' && kv[1].length > 0) {
      found += 1;
    }
  });
  return found === 2;
};
const checkStorage = () => {
  let found = 0;
  const session_key = localStorage.getItem('session_key');
  const session_user = localStorage.getItem('session_user');
  const session_expires = localStorage.getItem('session_expires');
  const now = moment().format('YYYYMMDDHHmmss');
  if (session_expires && parseInt(session_expires) < parseInt(now)) {
    return false;
  }
  if (session_key && session_key.length > 70) {
    found += 1;
  }
  if (session_user && session_user.length > 0) {
    found += 1;
  }
  if (session_expires && session_expires.length > 0) {
    found += 1;
  }

  return found === 3;
};

// Before it navigate to each page, it will run this.
// If the requiresAuth be set then it will check the cookies,
// if the cookies don't be valid, then it will redirect to the
// expired page.

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth === true && checkCookies() === false) {
    next({
      path: '/expired',
    });
  } else if (to.meta.requiresAuthByStorage === true && checkStorage() === false) {
    next({
      path: '/expired',
    });
  } else {
    console.log('router accessing page', to.name);
    next();
  }
});

export default router;
