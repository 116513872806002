<template>
  <MainLayout menu_id="history">
  <SenderContainer>
    <!-- <h1>e-attach - 添付履歴</h1> -->

    <ul class="nav nav-tabs" style="margin-top:50px;">
      <li class="nav-item">
        <a :class="(pageMode === 'active')? 'nav-link active':'nav-link inactive_tab'"  href="/history/active" v-html="_d('tab_active', '活動中', 'At history page the top tab for active')"></a>
      </li>
      <li class="nav-item">
        <a :class="(pageMode === 'pause')? 'nav-link active':'nav-link inactive_tab'"  href="/history/pause" v-html="_d('tab_pause', '一時停止','At history page the top tab for pause')" ></a>
      </li>
      <li class="nav-item">
        <a :class="(pageMode === 'expired')? 'nav-link active':'nav-link inactive_tab'"  href="/history/expired" v-html="_d('tab_expired', '期限切れ','At history page the top tab for expired')" ></a>
      </li>
      <li class="nav-item">
        <a :class="(pageMode === 'deleted')? 'nav-link active':'nav-link inactive_tab'"  href="/history/deleted" v-html="_d('tab_deleted', '削除された','At history page the top tab for deleted')" ></a>
      </li>
      <li class="nav-item">
        <a :class="(pageMode === 'draft')? 'nav-link active':'nav-link inactive_tab'"  href="/history/draft" v-html="_d('tab_draft', '下書き','At history page the top tab for draft')" ></a>
      </li>
    </ul>
    <div style="margin-top: 20px;width:100%;display: inline-block;">
      <input type="text" class="form-control" style="width:200px;float: right;"
          @input="localSearchControlChangedDatasource"
          :placeholder="_d('search_placeholder', 'アタッチ名 検索', 'Search text at history page')" aria-label="検索" aria-describedby="basic-addon1"/>
    </div>

    <!-- <search-control
      style="padding-bottom: 10px;"
      @change="searchControlChangedDatasource"
      :single="true"
      :field_names="searchFieldList"></search-control> -->

      <ag-grid-vue style="width: 100%; height: 325px; margin-top: 7px;"
      class="ag-theme-bootstrap"
      :gridOptions="gridOptions"
      >
    </ag-grid-vue>

  </SenderContainer>
</MainLayout>
</template>
<script>
// import moment from 'moment';
import SenderContainer from '@/components/sender-container';
import MainLayout from '@/layouts/mainLayout';
import agListController from '@/mixins/ag-list-controller';
import _ from 'lodash';
import datasource from '@/mixins/datasource';
import Dictionary from '@/mixins/dictionary';
import { fields } from '../../../api/rules/fields_attach';

// const ATTACH_STATUS_TEXT = {
//   10: '下書き',
//   20: '活動中',
//   30: '一時停止',
//   40: '削除された',
//   50: '期限切れ',
// };
export default {
  data() {
    return {
      dict_prefix: 'history',
      name: 'history',
      api_name: 'attach',
      // detail_page: 'attach-form',
      loaded_data: [],
      is_loaded: false,
    };
  },
  routes: [{
    // path: '/menu', name: 'Menu', component: this, meta: { requiresAuth: true },
    path: '/history/:mode', name: 'History', component: this, meta: { requiresAuth: true },
  }],
  computed: {
    fieldList() {
      return fields;
    },
    tabFieldList() {
      let list = [
        _.extend(fields.link_mode, {
          tab_label: this._d('link_mode', 'リンク方向', 'At history page the column name for link_mode'),
          cellRenderer: (params) => {
            if (params.value === 'send') {
              return `<i class="bi bi-caret-up-square"></i> ${this._d('url_direction_send', 'Send', 'A text do indicate the direction of download, if is to receive of to send.')}`;
            } if (params.value === 'receive') {
              return `<i class="bi bi-caret-down-square-fill"></i> ${this._d('url_direction_receive', 'Receive', 'A text do indicate the direction of download, if is to receive of to send.')}`;
            }
            return '';
          },
        }),
        _.extend(fields.alias_name, { tab_label: this._d('alias_name', 'アタッチ名', 'At history page the column name for alias_name') }),
        _.extend(fields.create_time, { tab_label: this._d('create_time', '作成日', 'At history page the column name for create_time') }),
        _.extend(fields.attach_expire, { tab_label: this._d('attach_expire', '有効期限', 'At history page the column name for attach_expire') }),
        _.extend(fields.deleted_time, { tab_label: this._d('deleted_time', '削除された', 'At history page the column name for deleted_time') }),
        _.extend(fields.attach_count, { tab_label: this._d('attach_count', 'ファイルの数', 'At history page the column name for attach_count') }),
        _.extend(fields.download_qty, { tab_label: this._d('download_qty', 'ダウンロード数', 'At history page the column name for download_qty') }),
        // _.extend(fields.attach_status, {}),
      ];
      if (this.pageMode !== 'deleted') {
        list = list.filter((f) => f.id !== 'deleted_time');
      }
      console.log('TEST DEBUG 240112 (102 at history.vue)[15:11]: ', { list });
      return list;
    },
    searchFieldList() {
      return [
        _.extend(fields.alias_name, {}),
        _.extend(fields.create_time, {}),
      ];
    },
    pageMode() {
      return this.$route.params.mode;
    },
    displayName() {
      console.log('TEST DEBUG 231015 (29 at menu.vue)[18:11]: Load displayName', { });
      return localStorage.getItem('display_name');
    },
    isLoaded: {
      get() {
        return this.is_loaded;
      },
      set(v) {
        this.is_loaded = v;
      },
    },
    loadedData: {
      get() {
        return this.loaded_data;
      },
      set(v) {
        this.loaded_data = v;
      },
    },
  },
  mixins: [agListController, datasource, Dictionary],
  components: { MainLayout, SenderContainer },
  methods: {
    localSearchControlChangedDatasource(ev) {
      this.searchControlChangedDatasource({

        is_block_info: true,
        1: {
          block: '1',
          field: 'alias_name',
          value: ev.target.value,
          ct: 'v',
          ca: false,
          t: 'input',
        },
        2: false,
        3: false,
        j1: 'AND',
        j2: 'AND',
        b2_disabled: false,
        b3_disabled: true,

      });
    },
    onRowSelected(ev) {
      console.log('TEST DEBUG 240112 (160 at history.vue)[16:59]: ', { ev });
      if (this.pageMode === 'draft') {
        if (ev.data?.link_mode === 'receive') {
          return this.$router.push({ name: 'uploadNewLink', params: { key: ev.node.data.id } });
        }
        return this.$router.push({ name: 'New', params: { key: ev.node.data.id } });
      } if (ev.data?.link_mode === 'receive') {
        return this.$router.push({ name: 'uploadLink', params: { key: ev.node.data.id } });
      }
      return this.$router.push({ name: 'Attach', params: { key: ev.node.data.id } });
    },
    createColumnDefs() {
      return this.commonCreateColumnDefs({ show_details: false, show_checkbox: false, force_sort: { id: 'create_time', direction: 'desc' } });
    },
    onOpenAttach(attach_id) {
      console.log('TEST DEBUG 231028 (84 at history.vue)[14:59]: ', { attach_id });
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    this.api_request_options = { mode: this.$route.params.mode };
    this.gridOptions = _.extend(this.commonGridOptions, {});
    this.initDatasource({});
  },
  mounted() {
    this.gridOptions.api.sizeColumnsToFit();
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style >
.btn-large {
  margin: 10px 0px 10px 0px;;
  width:300px;
}
.btn-primary {
    background-color: #6b71f2; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}
.inactive_tab{
  color: #6268e0;
}

a.attach_link{
  color: #6268e0;
  font-size: 16px;;
}

.ag-header-cell-text{
  font-size: 16px!important;
  font-weight: bold!important;
}

.ag-row {
  font-size: 16px!important;
  height: 35px!important;
  color: #6268e0!important;
}
.ag-cell {
  margin-top: 5px!important;
  margin-bottom: 5px!important;
}
.ag-theme-bootstrap .ag-ltr .ag-cell {
  border:none!important;
}
.ag-row:hover {
  background-color: #6b71f2!important;
  color: white!important;

}
.ag-row-selected {
  background-color: #6268e0!important;
  color: white!important;
}
.ag-theme-bootstrap{
  border: 1px solid #ccc!important;
}

.ag-header-cell:not(:first-child){
  border-left: 1px solid #ddd!important;
}

</style>
