<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div class="container-fluid" style="background:rgb(255, 255, 255);border-bottom: 2px #6b71f3 double;padding-bottom:20px;">
        <div class="responsive-left-margin" style="float:left;">
          <ul class="nav ">
            <li class="nav-item">
              <a class=" "  style="padding-top: 0px;margin-top: 0px;" @click.prevent="navigateTo('/')">
              <img style="height: 60px;" src="/img/logo_h.png" alt="ホーム" /> </a>
            </li>
            <li class="nav-item" v-show="windowSize > 1">
              <span v-if="menu_id=='history'" style="width:150px;" class="nav-link btn" data-cy="history-link">{{_d('top_link_history_active', '添付履歴', '')}}</span>
              <a v-else style="width:150px;" class="nav-link btn btn-primary" @click.prevent="navigateTo('/history/active')" data-cy="active-history-link">{{_d('top_link_history', '添付履歴', 'Link on the top of the page.')}}</a>
            </li>
            <li class="nav-item" v-show="windowSize > 1">
              <span v-if="menu_id=='new' || menu_id=='attach'" style="" class="nav-link  btn top-btn-large" data-cy="new-attachment-link">{{_d('top_new_attach_active', '新しいアタッチメント', 'Link on the top of the page.')}}</span>
              <a v-else style="" class="nav-link btn top-btn-large" @click.prevent="createNewAndGo" data-cy="create-new-attachment-link">{{_d('top_new_attach', '新しいアタッチメント', 'Link on the top of the page.')}}</a>
            </li>
            <li class="nav-item" v-show="windowSize > 1">
              <span v-if="menu_id=='upload_link'" style="" class="nav-link  btn top-btn-large" data-cy="upload-link">{{_d('top_upload_link_active', 'アップロードリンク', 'Link on the top of the page.')}}</span>
              <a v-else style="" class="nav-link btn top-btn-large" @click.prevent="createNewUploadLinkAndGo" data-cy="create-upload-link">{{_d('top_upload_link', 'アップロードリンク', 'Link on the top of the page.')}}</a>
            </li>
          </ul>
        </div>
        <div class="responsive-right-margin" style="float: right;">
          <nav class="my-navbar ">
            <button
              v-show="windowSize < 3"
              id="menu_button"
              @click="toggleMenu"
              class="my-navbar-toggler btn btn-light do_not_close_dialog"
              style="border: gray 1px solid;color: #6b71f2;">
                <span
                id="menu_button_icon"
                class="navbar-toggler-icon do_not_close_dialog"
                style="color: #6b71f2;"></span>
            </button>
            <div class="open_menu_right" v-show="windowSize > 2" style="height: 60px;" >
              <ul class="my-navbar-nav nav">
                <li class="nav-item">
                  <span v-if="menu_id=='settings'" style="width:70px;" class="nav-link  btn" data-cy="settings-link">{{_d('top_settings_active', '設定', 'Link on the top of the page.')}}</span>
                  <a class="nav-link" style="width:70px;" v-else @click.prevent="navigateTo('/settings/my-data')" data-cy="my-data-link">{{_d('top_settings', '設定', 'Link on the top of the page.')}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"  @click.prevent="navigateTo('/logout')" data-cy="logout-link">{{_d('top_logout', 'ログアウト', 'Link on the top of the page.')}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link disabled" style="max-width: 269px;" ><i class="bi bi-person-fill"></i> {{displayName }}</a>
                  <span></span>
                </li>
              </ul>
            </div>
            <div
              id="menu_dialog"
              class="do_not_close_dialog closed_menu dialog"
              :style="(windowSize == 1)?'height:240px;top: 243px;':'height:130px;top: 133px;'"
              v-show="isMenuVisible">
              <div style="padding-left:10px;padding-top:10px">
                <a class="do_not_close_dialog nav-link disabled" ><i class="do_not_close_dialog bi bi-person-fill"></i> {{displayName }}</a><br>
              </div>
              <div style="padding-left:0px;padding-bottom:20px;" v-show="windowSize == 1">
                <a class="do_not_close_dialog nav-link-in-menu" @click.prevent="navigateTo('/history/active')"   data-cy="active-history-link">{{_d('top_link_history', '添付履歴', 'Link on the top of the page.')}}</a>
                <a class="do_not_close_dialog nav-link-in-menu" @click.prevent="createNewAndGo"                  data-cy="create-new-attachment-link">{{_d('top_new_attach', '新しいアタッチメント', 'Link on the top of the page.')}}</a>
                <a class="do_not_close_dialog nav-link-in-menu" @click.prevent="createNewUploadLinkAndGo"        data-cy="create-upload-link">{{_d('top_upload_link', 'アップロードリンク', 'Link on the top of the page.')}}</a>
              </div>

              <div style="padding-left:0px;">
                <a class="do_not_close_dialog nav-link-in-menu"  @click.prevent="navigateTo('/settings/my-data')" data-cy="my-data-link">{{_d('top_settings', '設定', 'Link on the top of the page.')}}</a>
                <a class="do_not_close_dialog nav-link-in-menu"  @click.prevent="navigateTo('/logout')"           data-cy="logout-link">{{_d('top_logout', 'ログアウト', 'Link on the top of the page.')}}</a>
              </div>

            </div>
          </nav>
        </div>
      </div>
  </nav>

    <main style="padding-top: 100px;padding-bottom: 100px; margin:0px;">
      <slot></slot> <!-- Page-specific content will go here -->
    </main>
    <footer class="footer mt-auto py-0 bg-light fixed-bottom" style="border-top: 2px #6b71f3 double;padding-top:20px;">
        <div class="container-fluid">
          <div class="responsive-left-margin" style="float:left;"></div>
          <div class="responsive-right-margin" style="float:right;">
            {{getFooterMessage()}}
            <ul class="nav " v-show="false">
              <li class="nav-item">
                <a class="nav-link nav-link-footer"  style="background-color: transparent;color:#6b71f3;"  href="#">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-footer"   style="background-color: transparent;color:#6b71f3;" href="#">Privacy</a>
              </li>
            </ul>
            </div>
        </div>
    </footer>
    <ConfirmationModal ref="confirmationModalRef"></ConfirmationModal>
</template>
<script>

import Dictionary from '@/mixins/dictionary';
import ConfirmationModal from '@/components/modals/confirmation-modal.vue';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

export default {
  data() {
    return {
      dict_prefix: 'main_layout',
      confirmation_modal_config: {},
      is_menu_visible: false,
      window_size: 0,
      timeout_controller: null,
    };
  },

  computed: {
    windowSize: {
      get() {
        return this.window_size;
      },
      set(v) {
        this.window_size = v;
      },
    },
    isMenuVisible: {
      get() {
        return this.is_menu_visible;
      },
      set(v) {
        this.is_menu_visible = v;
      },
    },
    displayName() {
      return localStorage.getItem('display_name');
    },
  },
  mixins: [Dictionary],
  components: { ConfirmationModal },
  methods: {
    toggleMenu() {
      console.log('toggleMenu');
      this.isMenuVisible = !this.isMenuVisible;
    },
    checkWindowSize() {
      if (window.innerWidth >= 1230) {
        this.windowSize = 3;
        this.isMenuVisible = false;
      } else if (window.innerWidth >= 810) {
        this.windowSize = 2;
      } else {
        this.windowSize = 1;
      }
    },
    closeDialog(e) {
      if (this.isMenuVisible) {
        const el = e.target.classList;
        if (!el.contains('do_not_close_dialog')) {
          this.isMenuVisible = false;
        }
      }
    },
    getFooterMessage() {
      const application_env_name = this._gd('application_env_name', 'env_name', 'A message in the bottom of the page to inform the version.');
      if (application_env_name === 'production') {
        return this._d('bottom_message_prod', '本番環境', 'A message in the bottom of the page.');
      }
      return this._d('bottom_message_dev', '開発中', 'A message in the bottom of the page.');
    },
    checkBeforeLeave(cb) {
      if (typeof this.$props.before_leave == 'function') {
        return this.$props.before_leave((allowed) => {
          cb(allowed);
        });
      }
      cb(true); // True is OK to leave.
    },
    navigateTo(url) {
      this.checkBeforeLeave((allowed) => {
        if (allowed) {
          this.$router.push(url);
        }
      });
    },
    createNewUploadLinkAndGo() {
      this.checkBeforeLeave((allowed) => {
        if (allowed) {
          this.confirmation_modal_config = {
            title: this._gd('title_create_new_upload', '新しいアップロード URL を作成する', 'The title of dialog to choose to create a new upload'),
            body: this._gd('message_create_new_upload', '新しいアップロード URL を作成することを確認します', 'The message of dialog to choose to create a new upload'),
            buttons: [
              {
                label: this._gd('btn_yes_create_new_upload', '新しい URL を作成する', 'At button to confirm to create a new URL'),
                class: 'btn-primary',
                cy: 'btn_create_new_upload',
                action: () => {
                  const api_url = '/attach/new_upload_link';
                  const body = {};
                  this.$ajax.post(api_url, body, (err, result) => {
                    console.log({ err, result, body });
                    if (err) {
                      this.$notify({
                        type: 'error',
                        title: this._d('error_unexpected_at_new_upload_link', '予期しないエラー', 'The title of unexpected error'),
                        text: err,
                      });
                    } else {
                      this.$router.push(`/new_upload_link/${result.data.id}`);
                    }
                  });
                },
              },
              {
                label: this._gd('btn_access_existing_upload', '既存のURLにアクセスします', 'At button to confirm to access the existing URL'),
                class: 'btn-secondary',
                cy: 'btn_access_existing_upload',
                action: () => {
                  this.$router.push('/history/draft');
                },
              },
            ],
          };
          this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
        }
      });
    },
    createNewAndGo() {
      this.checkBeforeLeave((allowed) => {
        if (allowed) {
          this.confirmation_modal_config = {
            title: this._gd('title_create_new_download', '新しいアタッチメント URL を作成する', 'The title of dialog to choose to create a new download'),
            body: this._gd('message_create_new_download', '新しいアタッチメント URL を作成することを確認します', 'The message of dialog to choose to create a new download'),
            buttons: [
              {
                label: this._gd('btn_yes_create_new_download', '新しい URL を作成する', 'At button to confirm to create a new URL'),
                class: 'btn-primary',
                cy: 'btn_create_new_upload',
                action: () => {
                  const api_url = '/attach/new';
                  const body = {};
                  this.$ajax.post(api_url, body, (err, result) => {
                    console.log({ err, result, body });
                    if (err) {
                      this.$notify({
                        type: 'error',
                        title: this._d('error_unexpected_at_create_new_attachment', '予期しないエラー', 'The title of unexpected error'),
                        text: err,
                      });
                    } else {
                      this.$router.push(`/new/${result.data.id}`);
                    }
                  });
                },
              },
              {
                label: this._gd('btn_access_existing_download', '既存のURLにアクセスします', 'At button to confirm to access the existing URL'),
                class: 'btn-secondary',
                cy: 'btn_access_existing_upload',
                action: () => {
                  this.$router.push('/history/draft');
                },
              },
            ],
          };
          this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
        }
      });
    },
    informLoginExpired() {
      this.confirmation_modal_config = {
        title: this._gd('title_session_expired', 'セッションの期限が切れました', 'The title of dialog to inform the session expired'),
        body: this._gd('message_session_expired', 'セッションが無効になりました。続行するには再度ログインしてください。', 'The message of dialog to inform the session expired'),
        buttons: [
          {
            label: this._gd('btn_session_expired_login_again', 'ログインページへ', 'At button to go to the login page'),
            class: 'btn-primary',
            cy: 'btn_session_expired_login_again',
            action: () => {
              this.$router.push('/login');
            },
          },
        ],
      };
      this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
    },
    scheduleLogOut(check_interval) {
      console.log('scheduleLogOut', { check_interval });
      if (this.timeout_controller) {
        clearInterval(this.timeout_controller);
      }
      this.timeout_controller = setInterval(() => {
        const session_user_session_timeout = this.$cookies.get('session_user_session_timeout');
        const session_last_activity = this.$cookies.get('session_last_activity');
        if (session_user_session_timeout && session_last_activity) {
          const now = new Date().getTime();
          const last_activity = (new Date(session_last_activity * 1000)).getTime();
          const timeout = parseInt(session_user_session_timeout) * 60 * 1000;
          const pending_time = parseInt((timeout - (now - last_activity)) / 1000);
          if (pending_time <= 0) {
            console.log('Session timeout');
            if (this.timeout_controller) {
              clearInterval(this.timeout_controller);
            }
            this.informLoginExpired();
          } else {
            console.log('Session is active, will expire in', pending_time, 'seconds');
            if (pending_time > 300 && check_interval < 60) {
              this.scheduleLogOut(60);
            } else if (pending_time < 120 && check_interval > 10) {
              this.scheduleLogOut(parseInt(pending_time / 2));
            }
          }
        }
      }, 1000 * check_interval);
    },

  },
  props: ['menu_id', 'before_leave'],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
    window.addEventListener('resize', this.checkWindowSize);
    document.addEventListener('click', this.closeDialog);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
    this.checkWindowSize();
    this.scheduleLogOut(10);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    if (this.timeout_controller) {
      clearInterval(this.timeout_controller);
    }
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
    if (this.timeout_controller) {
      clearInterval(this.timeout_controller);
    }

    window.removeEventListener('resize', this.checkWindowSize);
    document.removeEventListener('click', this.closeDialog);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>

.dialog {
  position: fixed;
  top: 233px;
  right: 3px;
  width: 255px;
  height: 230px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  transform: translateY(-100%);
  z-index: 10000;
}
.dialog.v-enter-active, .dialog.v-leave-active {
  transition: transform 0.3s ease-in-out;
}
.dialog.v-enter, .dialog.v-leave-to {
  transform: translateY(-100%);
}
.dialog.v-enter-to, .dialog.v-leave {
  transform: translateY(0);
}

@media (max-width: 1561px) {
  .responsive-left-margin {
    margin-left: 10px;
  }
  .responsive-right-margin {
    margin-right:10px;
  }
  .top-btn-large{
    width:160px!important;
  }
}
@media (min-width: 1562px) {
  .responsive-left-margin {
    margin-left: 100px;
  }
  .responsive-right-margin {
    margin-right:100px;
  }
  .top-btn-large{
    width:200px!important;
  }

}
a.nav-link {
  margin-left: 5px;
  color: #6b71f2;
  background-color: transparent;
  border-radius: 9px;
}
a.nav-link:hover {

  color: white;
  background-color: #4e56e1;
  border-radius: 9px;
}

a.nav-link-in-menu {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

  margin-left: 5px;
  color: #6b71f2;
  background-color: transparent;
  padding-left: 10px;
  /* border-radius: 9px; */
}
a.nav-link-in-menu:hover {
  background-color: #4e55e134;
}

a.nav-link-footer:hover {
  text-decoration: underline;
  font-weight: 500;
}
span.nav-link {
  width: 150px;;
  margin-left: 5px;
  color: white;
  background-color: #6b71f2;
  border-radius: 9px;
}
</style>
