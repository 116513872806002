const { TYPES } = require('./constants'); // SELECT_FIELD_MODE
//

const arr = [
  { id: 'id', label: 'Unique Key', type: TYPES.SYSTEM },

  {
    id: 'display_name',
    label: 'User Name',
    type: TYPES.INPUT,
  },
  {
    id: 'attach_id',
    label: 'Attach ID',
    type: TYPES.INPUT,
  },
  {
    id: 'alias_name',
    label: 'Attach name',
    type: TYPES.INPUT,
  },
  {
    id: 'log_datetime',
    label: 'Event time',
    type: TYPES.DATE_PICKER,
    show_time: true,
    format: 'YYYY/MM/DD HH:mm:ss',
  },
  {
    id: 'log_type',
    label: 'Log Type',
    type: TYPES.INPUT,
  },
  {
    id: 'log_data',
    label: 'Log Details',
    type: TYPES.INPUT,
  },
  {
    id: 'session_id',
    label: 'Session',
    type: TYPES.INPUT,
  },
  {
    id: 'ip',
    label: 'IP',
    type: TYPES.INPUT,
  },
];
const ret = {};
let s = 1;
arr.forEach((e) => {
  /* eslint-disable no-alert, no-console */
  const ex_pattern = '^[a-z][a-z0-9_]+$';
  if (!RegExp(ex_pattern).test(e.id)) { alert(`Invalid field ${e.id}`); }
  e.tab_label = e.tab_label || e.label;
  e.seq_id = s;
  s += 1;
  ret[e.id] = e;
  /* eslint-enable no-alert, no-console */
});
ret.array = arr;
module.exports.fields = ret;
module.exports.filed_types = TYPES;
