<template>
  <div>
    <div class="row" style="">
      <div class="col-lg-4 col-sm-12">
        <label class="sr-only" for="mfa_required" v-html="_d('mfa_is_required', 'MFA が必要です', 'Field to ask if MFA is Required')"></label>
        <select  class="form-select" id="mfa_required" v-model="mfaIsRequired"
          :disabled="disabled" style="max-width: 1000px;" autocomplete="off" data-lpignore="true" data-cy="mfa-required">
          <option :disabled="false" :value="MFA_REQUIRED.NO">{{_d('mfa_required_no', 'なし', 'Option to inform no MFA is required')}}</option>
          <option v-if="allow_by_email == 'yes'" :disabled="false" :value="MFA_REQUIRED.EMAIL">{{_d('mfa_required_email', '電子メール', 'Option to inform emails is required for MFA')}}</option>
          <option v-if="allow_by_sms == 'yes'"  :disabled="false" :value="MFA_REQUIRED.SMS" >{{_d('mfa_required_sms', 'SMS', 'Option to inform sms is required for MFA')}}</option>
        </select>
      </div>
      <div class="col-lg-8 col-sm-12  mfa-options-custom-margin" v-if="mfaIsRequired > MFA_REQUIRED.NO">
        <label v-if="mfaIsRequired == MFA_REQUIRED.EMAIL"  class="sr-only" for="mfa_mode" v-html="_d('mfa_address_mode_mail', 'MFAアドレスモード 「メール」', ' Field to define if the MFA address mode for email')"></label>
        <label v-if="mfaIsRequired == MFA_REQUIRED.SMS"  class="sr-only" for="mfa_mode" v-html="_d('mfa_address_mode_sms', 'MFAアドレスモード 「SMS」', ' Field to define if the MFA address mode for sms')"></label>
        <select  class="form-select" id="mfa_mode" v-model="addressMode"
          :disabled="disabled" style=""
          autocomplete="off" data-lpignore="true" data-cy="mfa-mode">
          <option v-if="mfaIsRequired == MFA_REQUIRED.EMAIL" :disabled="false" :value="MFA_ADDRESS_MODE.ANY">{{_d('mfa_address_mode_any_email', 'どのメールでも使用可能', 'Option to inform that any email can be used')}}</option>
          <option v-if="mfaIsRequired == MFA_REQUIRED.EMAIL" :disabled="false" :value="MFA_ADDRESS_MODE.DEFINED">{{_d('mfa_address_mode_defined_mail_list', '事前に定義された電子メールのリストのみを使用できます', 'Option to inform that only the defined emails email can be used')}}</option>

          <option v-if="mfaIsRequired == MFA_REQUIRED.SMS" :disabled="false" :value="MFA_ADDRESS_MODE.ANY">{{_d('mfa_address_mode_any_sms', 'SMS には任意の電話番号を使用できます', 'Option to inform that any phone number can be used for SMS')}}</option>
          <option v-if="mfaIsRequired == MFA_REQUIRED.SMS" :disabled="false" :value="MFA_ADDRESS_MODE.DEFINED">{{_d('mfa_address_mode_defined_sms_list', 'SMS には、事前定義された電話番号のリストのみを使用できます。', 'Option to inform that only the defined phone number can be used for SMS')}}</option>
        </select>
      </div>
      <div class="col-12" v-if="mfaIsRequired > MFA_REQUIRED.NO && addressMode > MFA_ADDRESS_MODE.ANY" style="margin-top:20px;">
        <label v-if="mfaIsRequired == MFA_REQUIRED.EMAIL" class="sr-only" for="mfa_addresses"
        v-html="_d('mfa_address_list_mail', 'MFA アドレスのリスト「メール」', 'Field to define  the list  MFA addresses, for email')"
        ></label>
        <label v-if="mfaIsRequired == MFA_REQUIRED.SMS" class="sr-only" for="mfa_addresses"
        v-html="_d('mfa_address_list_sms', 'MFA アドレスのリスト 「SMS」', 'Field to define  the list  MFA addresses, for SMS')"
        ></label>
        <textarea class="form-control" id="mfa_addresses" v-model="addressList"
          :disabled="disabled" style="" autocomplete="off" data-lpignore="true"
          :placeholder="mfaAddressListPlaceholder"
          :rows="addressListCount" data-cy="mfa-addresses"
          ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
// import _ from 'lodash';
import Dictionary from '@/mixins/dictionary';

export default {
  data() {
    return {
      dict_prefix: 'mfa_area',
      address_mode: 0,
      address_list: '',
      mfa_is_required: 0,
      address_list_count: 3,
      mfa_address_list_placeholder: '',
      MFA_REQUIRED: {
        NO: 0,
        EMAIL: 1,
        SMS: 2,
      },
      MFA_ADDRESS_MODE: {
        ANY: 0,
        DEFINED: 1,
      },
    };
  },
  mixins: [Dictionary],
  components: {

  },
  computed: {
    mfaAddressListPlaceholder: {
      get() {
        if (this.mfaIsRequired === this.MFA_REQUIRED.EMAIL) {
          return this._d('mfa_email_placeholder', 'mail@exsample.com\naddres@exsample.com', 'A place holder for the E-Mail address list').replace(/<br>/g, '\n');
        }
        return this._d('mfa_sms_placeholder', '09099998888\n080-1111-2222', 'A place holder for the SMS address list').replace(/<br>/g, '\n');
      },
    },
    addressListCount: {
      get() {
        return this.address_list_count < 3 ? 3 : this.address_list_count;
      },
      set(v) {
        this.address_list_count = v;
      },
    },
    addressList: {
      get() {
        return this.address_list;
      },
      set(v) {
        this.address_list = v;
        const list = (v || '').split('\n');
        this.addressListCount = list.length;
        this.emitStatus();
      },
    },
    addressMode: {
      get() {
        return this.address_mode;
      },
      set(v) {
        this.address_mode = v;
        if (this.addressList === '*' && v === 1) {
          this.addressList = '';
        } else {
          this.addressList = '';
        }
        this.emitStatus();
      },
    },
    mfaIsRequired: {
      get() {
        return this.mfa_is_required;
      },
      set(v) {
        this.mfa_is_required = v;
        this.emitStatus();
      },
    },
  },
  mounted() {
    this.mfaIsRequired = this.$props.mfa_required;
    this.addressMode = (this.$props.mfa_address_list || '*').trim() === '*' ? 0 : 1;
    this.addressList = this.$props.mfa_address_list;
  },
  props: ['attach_id', 'disabled', 'mfa_required', 'mfa_address_list', 'allow_by_email', 'allow_by_sms'],
  methods: {
    emitStatus() {
      this.$emit('onChange', {
        mfa_required: this.mfaIsRequired,
        address_mode: this.addressMode,
        address_list: this.addressList,
      });
    },
  },
};
</script>

<style scoped>
.download_tr:hover{
  background-color: #f7f7f7;
}
.btn-primary {
    background-color: #6b71f2; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}

@media (max-width: 991px) {
  .mfa-options-custom-margin {
    margin-top: 15px;
  }
}
</style>
